import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SinglePagePDFViewer from "../../components/pdf/single-page";
import AllPagesPDFViewer from "../../components/pdf/all-pages";
import resumePDF from "../../assets/images/Resume.pdf";
import DownloadIcon from "../../components/icons/Download"

import { Container, Row, Col } from "react-bootstrap";
import { resume, meta } from "../../content_option";

    // Function will execute on click of button
    const onButtonClick = () => {
      // using Java Script method to get PDF file
      fetch('Resume.pdf').then(response => {
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = 'Adam Tolberts Resume.pdf';
              alink.click();
          })
      })
  }

export const Resume = () => {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Resum&eacute; | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h2 className="display-4 mb-4 resumeTitle"><div>Resum&eacute;</div> <button className="iconButton" onClick={onButtonClick}><h1><DownloadIcon/></h1></button></h2>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <div className="mb-5 po_items_ho">
          <div className="all-page-container">
            {/* <SinglePagePDFViewer pdf={resumePDF} /> */}
            {/* <AllPagesPDFViewer pdf={resumePDF} /> */}
          </div>
        </div>
      </Container>
    </HelmetProvider>
  );
};
