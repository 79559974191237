const logotext = "Adam Tolbert";
const meta = {
    title: "Adam Tolbert",
    description: "Online portfolio for Web/Salesforce Developer Adam Tolbert",
};

const introdata = {
    title: "Adam Tolbert",
    animated: {
        first: "Saleforce Developer",
        second: "Full-Stack Web Developer",
        third: "Certified Saleforce Admin",
    },
    description: "Welcome to my website! This is a place where you can learn a little about me and my skillset as a Salesforce / Web Developer. I developed this website using React and am hosting the website via Netlify using a continuous integration with a github repository. I built and maintain this website to exhibit my professional profile while keeping my web developemnt skills sharp outside the Salesforce ecosystem.",
    // your_img_url: "./assets/images/art_along_the_bounderies.jpg",
};

const dataabout = {
    title: "Who am I?",
    aboutMeParagraphs: [
        "I am a Certified Full Stack Web Developer & Certified Salesforce Admin specializing in developing real-time data integrations using a variety of techniques. This includes coding and consuming APIs within and outside of the Salesforce ecosystem, as well as using real-time integration tools to seamlessly share data between systems. In addition to moving data, I also am well-versed in managing/manipulating raw data in external data stores (SQLServer, MongoDB, etc) to facilitate smoother and more complete integrations.",
        "I am also experienced in developing full-stack applications using frontend frameworks (React, LWC, Visualforce, Aura, etc.) that allow the end users to interact with the application/functionalities operating in the backend easier and in a more managable way.",
        "I enjoy working in fast paced environments that require an ability to adapt to constantly changing requirements and priorities. My strengths include organization, responsiveness, critical thinking, multi-tasking, data analytics, end-to-end development, UI/UX design, knowledge acquisition, cooperation, and negotiation."
    ]
};
const worktimeline = [
    {
        jobtitle: "Lead Salesforce Developer",
        where: "DLL Group",
        date: "2022-Now"
    },
    {
        jobtitle: "Salesforce Developer",
        where: "SMG3",
        date: "2019-2022"
    },
    {
        jobtitle: "Salesforce Developer",
        where: "Skyline Steel",
        date: "2019-2021"
    },
    {
        jobtitle: "Network Technician",
        where: "GLS",
        date: "2018-2019"
    },
    {
        jobtitle: "Freight Broker",
        where: "NTG",
        date: "2016-2018"
    },
];

const sfCertificates = [
    {
        title: "SF Certified Admin 1",
        date: "2019"
    },
    {
        title: "Platform App Developer",
        date: "2023"
    },
    {
        title: "SF Developer 1",
        date: "2023"
    },
];

const skills = [
    {
        name: "Apex",
        value: 90,
    },
    {
        name: "SOQL",
        value: 80,
    },
    {
        name: "Aura/Lightning",
        value: 75,
    },
    {
        name: "Javascript",
        value: 85,
    },
    {
        name: "React",
        value: 60,
    },
    {
        name: "Database Management",
        value: 75,
    },
];

const services = [{
        title: "Application Development",
        description: "Building applications that facilitate the interaction between users and Salesforce by designing streamlined data structures, dynamic and interactive user interfaces, and automating business deicisions as much as possible to limit the time required to perform tasks.",
    },
    {
        title: "Realtime Integrations / APIs",
        description: "Designing and building realtime integrations using direct connections to SOQL Databases and systematic Apex calls out to external APIs, to allow users to access data across multiple platforms in a single place in realtime. ",
    },
    {
        title: "Client Experience Management",
        description: "Building out sites that will grant controllable and specific access to users outside of your organization so that they can access their data in your system from anywhere.",
    },
];

const dataportfolio = [{
        // img: URL('./assets/images/memior_cover_no_text.jpg'),
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "aotolbert@gmail.com",
    YOUR_FONE: "(404) 725-1574",
    description: "Send me a message directly by filling out and submitting this form:",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/aotolbert",
    facebook: "https://facebook.com",
    linkedin: "https://linkedin.com/in/adamotolbert",
    twitter: "https://twitter.com",
    youtube: "https://youtube.com",
    salesforce: "https://trailblazer.me/id/atolbert",
};

const resume = {
    fileLocation: "./assets/images/Resume.pdf",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    sfCertificates,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
    resume,
};
