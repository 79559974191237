
import React, { Component } from 'react';
import { HiOutlineDocumentDownload } from "react-icons/hi";

class Download extends React.Component {
  render() {
    return <HiOutlineDocumentDownload /> 
  }
}

export default Download;